const registerApplication = (comp) => {
    if (comp) {
        import(
        /* webpackChunkName: "[request]" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        /* webpackInclude: /^(?!.*\.test\.js$).*\.js$/ */
        /* webpackExclude: /[\/]__tests__[\/]/ */
        `${comp}.js`).then(element => {
            window.comp = element;
            window.comp.init();
        });
    }
};
export async function Load(elements) {
    const components = elements || [
        "TmsJoblist"
    ];
    for (let i = 0; i < components.length; i++) {
        const component = components[i];
        registerApplication(component);
    }
}
